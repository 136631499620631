<template>
  <div class="time_type_box">
    <selectComp
      class="time_type"
      :hval="timeType"
      :data="timeTypeList"
      @change="timeTypeChange"
      :clearable="false"
      placeholder="时间类型"
    />
    <dateComp
      v-show="timeType === 1 || timeType === 2"
      :hval="date"
      @change="dateChange"
    />
    <div class="month_box" v-show="timeType === 3">
      <el-date-picker
        v-model="beginMonth"
        type="month"
        size="small"
        placeholder="起始月"
        :clearable="false"
        style="width:126px"
        @change="beginMonthChange"
      >
      </el-date-picker>
      <div style="margin:0 5px">-</div>
      <el-date-picker
        v-model="endMonth"
        type="month"
        size="small"
        placeholder="截止月"
        :clearable="false"
        style="width:126px"
        @change="endMonthChange"
      >
      </el-date-picker>
    </div>
  </div>
</template>
<script>
import { formatDate, diffDate } from '@/utils/common'
export default {
  props: {
    timeTypeList: {
      type: Array,
      default: () => [
        { label: '按天', value: 1 },
        { label: '按月', value: 3 }
      ]
    }
  },
  data () {
    return {
      timeType: 3,
      beginMonth: '',
      endMonth: '',
      date: [],
      startTime: '',
      endTime: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // 按日统计， 以今天为起点，向前推6天，共计7天。
      // 按月统计，以当前月伟起点，向前推5月，共计6月。
      // 按周统计，以当前周伟起点，向前推5周，共计6周。
      if (this.timeType === 1) {
        this.endTime = formatDate(new Date())
        this.startTime = diffDate(-6, 'days', true)
        this.date = [diffDate(-6, 'days'), new Date()]
      } else if (this.timeType === 3) {
        this.endMonth = new Date()
        this.beginMonth = diffDate(-5, 'months')
        this.endTime = formatDate(new Date()).slice(0, 7)
        this.startTime = diffDate(-5, 'months', true).slice(0, 7)
      } else {
        this.endTime = formatDate(new Date())
        this.startTime = diffDate(-7, 'weeks', true)
        this.date = [diffDate(-7, 'weeks'), new Date()]
      }
      this.emitData()
    },
    timeTypeChange (val) {
      this.timeType = val
      this.init()
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
      this.emitData()
    },
    beginMonthChange (val) {
      this.startTime = formatDate(val).slice(0, 7)
      this.emitData()
    },
    endMonthChange (val) {
      this.endTime = formatDate(val).slice(0, 7)
      this.emitData()
    },
    emitData () {
      this.$emit('changeTime', {
        timeType: this.timeType,
        startTime: this.startTime,
        endTime: this.endTime
      })
    },
    resetData () {
      this.timeType = 3
      this.beginMonth = ''
      this.endMonth = ''
      this.date = []
      this.startTime = ''
      this.endTime = ''
      this.init()
    }
  }
}
</script>
<style lang="scss" scoped>
.time_type_box {
  display: flex;
  .time_type {
    margin-right: 8px;
  }
  .month_box {
    display: flex;
    align-items: center;
  }
}
</style>
