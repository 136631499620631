<template>
  <div class="layout_common device">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <timeType ref="timeTypeRef" @changeTime="changeTime" />
            <selectComp
              :hval="orderType"
              :data="orderTypeList"
              @change="orderTypeChange"
              :clearable="false"
              placeholder="周期"
            />
            <selectComp
              v-if="orderType === 2"
              :hval="statisticsType"
              :data="statisticsTypeList"
              @change="statisticsTypeChange"
              :clearable="false"
              placeholder="周期"
            />
            <merchant
              v-if="!isStore"
              :hval="storeName"
              @storeNameChange="storeNameChange"
              showRankBtn
              @rankClick="isShow = true"
            />
            <btnGroup @search="search" @reset="reset" />
          </div>
        </div>
        <div class="echart" v-loading="loading">
          <barEchart
            id1="device_box"
            id2="device_echart"
            :propData="echartData"
            :barColor="barColor"
          />
        </div>
      </div>
    </div>
    <!-- 商家发货排名 -->
    <rank :isShow="isShow" @close="isShow = false" @selectStore="selectStore" />
  </div>
</template>
<script>
import barEchart from '../components/agingBar'
import timeType from '../components/timeType'
import merchant from '@/views/components/merchant'
import rank from '../components/rank'
import { deviceRepair } from '@/api'
import { localGet } from '@/utils/common'
import {
  statisticsTypeListToDevice,
  orderTypeListToDevice
} from '@/utils/constData'
export default {
  components: {
    timeType,
    barEchart,
    merchant,
    rank
  },
  data () {
    return {
      loading: false,
      isShow: false,
      orderType: 1,
      statisticsType: '',
      storeId: '',
      storeName: '',
      startTime: '',
      endTime: '',
      echartData: []
    }
  },
  computed: {
    // 是否是商家角色
    isStore () {
      return localGet('loginUserInfo').businessRole === 'store'
    },
    statisticsTypeList () {
      return statisticsTypeListToDevice
    },
    orderTypeList () {
      return orderTypeListToDevice
    },
    barColor () {
      if (this.echartData.length <= 2) {
        return ['#5470c6', '#fac858']
      } else {
        return ['#5470c6', '#91cc75', '#fac858', '#ea7ccc', '#73c0de']
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    changeTime ({ timeType, startTime, endTime }) {
      this.timeType = timeType
      this.startTime = startTime
      this.endTime = endTime
    },
    getData () {
      const params = {
        timeType: this.timeType,
        start: this.startTime,
        end: this.endTime,
        orderType: this.orderType,
        statisticsType: this.statisticsType,
        storeId: this.storeId
      }
      this.loading = true
      deviceRepair(params).then(res => {
        this.loading = false
        this.handleEchartData(res.data)
      })
    },
    // 根据后台返回的数据进行处理成图表的格式的数据
    handleEchartData (data) {
      const arr = []
      if (this.orderType === 1) {
        arr[0] = this.handleMapData(
          data,
          '报修设备数量',
          'equipmentRepairCount'
        )
        arr[1] = this.handleMapData(data, '开箱不良数量', 'poorUnpackingCount')
      } else {
        switch (this.statisticsType) {
          case 1:
            arr[0] = this.handleMapData(data, '远程处理', 'remote')
            arr[1] = this.handleMapData(data, '上门处理', 'visite')
            break
          case 2:
            arr[0] = this.handleMapData(data, '400电话', 'sourceo')
            arr[1] = this.handleMapData(data, '销售转述', 'sourcet')
            arr[2] = this.handleMapData(data, '微信联系', 'sourcetr')
            arr[3] = this.handleMapData(data, '在线客服', 'sourcef')
            arr[4] = this.handleMapData(data, '扫码报修', 'sourcefi')
            break
          case 3:
            arr[0] = this.handleMapData(data, '非二次处理', 'negative')
            arr[1] = this.handleMapData(data, '是二次处理', 'determine')
            break
          case 4:
            arr[0] = this.handleMapData(data, '非客户催促', 'negative')
            arr[1] = this.handleMapData(data, '是客户催促', 'determine')
            break
          case 5:
            arr[0] = this.handleMapData(data, '非客户投诉', 'negative')
            arr[1] = this.handleMapData(data, '是客户投诉', 'determine')
            break
          case 6:
            arr[0] = this.handleMapData(data, '非客情单', 'negative')
            arr[1] = this.handleMapData(data, '是客情单', 'determine')
            break
          case 7:
            arr[0] = this.handleMapData(data, '租赁业务', 'zlprofessional')
            arr[1] = this.handleMapData(data, '大客业务', 'dkprofessional')
            arr[2] = this.handleMapData(data, '增值服务', 'zzprofessional')
            break
          default:
            break
        }
      }
      this.echartData = arr
    },
    handleMapData (data, name, value) {
      return {
        name,
        value: data.map(item => item[value]),
        dataX: data.map(item => item.createTime)
      }
    },
    orderTypeChange (val) {
      this.orderType = val
      if (val === 2) {
        this.statisticsType = 1
      } else {
        this.statisticsType = ''
      }
    },
    statisticsTypeChange (val) {
      this.statisticsType = val
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    // 选择的商家
    selectStore (val) {
      this.storeId = val.storeId
      this.storeName = val.storeName
    },
    search () {
      this.getData()
    },
    reset () {
      this.$refs.timeTypeRef.resetData()
      this.storeName = ''
      this.storeId = ''
      this.orderType = 1
      this.statisticsType = ''
      this.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
.device {
  .echart {
    margin-top: 20px;
    height: calc(100% - 120px);
  }
}
</style>
